export interface KioskConfig {
  version?: string
  application: KioskApplication
  url: string
  whitelist: string
  whitelistUrl?: string
  homeTime: number
  idleTime: number
  backgroundColor?: string
  foregroundColor?: string
  screenlabEnabled: boolean
  showNavigation: boolean
  navigationBarPosition: KioskNavigationBarPosition
  cacheFlushEnabled: boolean
  virtualKeyboardEnabled: boolean
  rotationEnabled: boolean
}

export enum KioskNavigationBarPosition {
  LEFT = "LEFT",
  TOP = "TOP",
  RIGHT = "RIGHT",
  BOTTOM = "BOTTOM",
  NONE = "NONE"
}

export enum KioskApplication {
  SCREENLAB = "SCREENLAB",
  HUMETIPS = "HUMETIPS",
  HUMECHARITY = "HUMECHARITY",
  HUMEORDER = "HUMEORDER",
  HUMESERVICES = "HUMESERVICES",
  CUSTOM = "CUSTOM"
}
